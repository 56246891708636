import React, { useEffect, useState, useRef } from "react";
import Graph from "react-vis-network-graph";
import { VisJsTree, VisJsNode } from "./visjsTree";
import * as FAMILY_ACTION from "./../../actions/familyTreeAction";
import { find } from "lodash";
import { Alert } from "@mui/material";

// const sample_input_data = {
//   "1": {
//     "uid": "1",
//     "name": "भगवानदास",
//     "parent_uid": "63",
//     "marriage_node_id": "23",
//     "spouse": {
//       "uid": "24",
//       "name": "अजंता",
//       "is_mukhiya": null
//     }
//   },
//   "63": {
//     "uid": "63",
//     "name": "मूलचंद",
//     "marriage_node_id": "43",
//     "spouse": {
//       "uid": "143",
//       "name": "अनु"
//     }
//   },
//   "124": {
//     "uid": "124",
//     "name": "उमा",
//     "parent_uid": "1"
//   },
//   "163": {
//     "uid": "163",
//     "name": "ख़ुशाल",
//     "parent_uid": "1"
//   },
//   "179": {
//     "uid": "179",
//     "name": "धर्मकला",
//     "parent_uid": "63"
//   },
//   "203": {
//     "uid": "203",
//     "name": "सांगीदान",
//     "parent_uid": "63"
//   },
//   "323": {
//     "uid": "323",
//     "name": "दुर्गा",
//     "parent_uid": "63"
//   }
// };

const FamilyTreeGraph = () => {

  const backendGraphData = useRef(new VisJsTree({}));
  const [graphData, setGraphData] = useState(null);
  // const [network, setNetwork] = useState(null);
  const networkRef = useRef(null);
  const [loadingNodeId, setLoadingNodeId] = useState(null);

  const focusOnNode = (nodeId) => {
    networkRef.current.focus(nodeId, {
      // scale: 1.5, // Zoom level
      offset: { x: 0, y: 0 },
      animation: {
        duration: 1000,
        easingFunction: "linear",  // "easeInOutQuad"
      },
    });
  }

  useEffect(() => {
      fetchFamilyData("1");
  }, []);

  const fetchFamilyData = async (personId) => {
    try {
        setLoadingNodeId(personId);
        const response = await FAMILY_ACTION.getFamilyInfo(personId, "json_tree");
        if (!response.status) {
          console.log("Error fetching family data" + response.error_message);
          alert('Error fetching family data' + response.error_message);
          return;
        }
        const data = response.result;
        backendGraphData.current.updateTree(data);
        setGraphData(backendGraphData.current.getVisJsTreeData(personId));
        focusOnNode(personId);
    } catch (error) {
        console.error("Error fetching family data:", error);
    } finally {
        setLoadingNodeId(null);
    }
  };

  if (!graphData) return <div>Loading...</div>;

  const options = {
    // layout: {
    //   hierarchical: {
    //     direction: "UD",
    //     // sortMethod: "directed",
    //     // nodeSpacing: 100,
    //   },
    // },
    nodes: {
      shape: 'circularImage',
      // brokenImage : "",
      // image : undefined,
      labelHighlightBold: true,
      // borderWidthSelected: 8,
      labelHighlightBold: true,
      borderWidth: 2,
      // size: 20,
      image: '',
      color: {
        border: '#222222',
        background: '#666666'
      },
      font: { color: '#eeeeee', size: 6 }
    },
    edges: {
      // color: "#000000",
      smooth: {
        type: "cubicBezier",// "dynamic", also works good (straight but issue with short edges of relationship node)
        forceDirection: "vertical",
        roundness: 0.8
      },
      width: 1,
      color: 'green',
    },
    height: "500px",
    physics: {
      enabled: true
    },
    interaction: {
      zoomView: true
    },
  };


  const findNodeInGraphDataByUid = (uid) => {
    // TODO: Explore if we can use Vis DataSet instead of this using reference or something similar
    return graphData.nodes.find(node => node.uid == uid);
  }

  const events = {
    select: ({nodes: nodeIds}) => {
      if (nodeIds.length < 1) {
        return;
      }
      const selectedNodeId = nodeIds[0];
      const selectedVisNode = networkRef.current.body.data.nodes.get(selectedNodeId);
      if (selectedVisNode.type == VisJsNode.NODE_TYPES.FEMALE_SPOUSE) {
        const personUid = selectedVisNode.originalNodeUid;
        fetchFamilyData(personUid);
      } else if (selectedVisNode.type == VisJsNode.NODE_TYPES.PERSON) {
        fetchFamilyData(selectedNodeId);
      } else if (selectedVisNode.type == VisJsNode.NODE_TYPES.ACTION) {
        if (selectedNodeId == 'GO_TO_PEEHAR' || selectedNodeId == 'GO_TO_SASURAL') {
          const moveToNodeId = selectedVisNode.moveToNodeId;
          focusOnNode(moveToNodeId);
        }
        // TODO: you get selectedNodeUid, relation_to_be_added, gender_of_relation_to_be_added
        // Call corresponding addUser functions with this data
      } else {
        console.log('No click action on this node', selectedVisNode);
      }
    },
  };

  const getLoadingText = (loadingNodeId) => {
    let loadingNodeText = 'आपकी जानकारी लाई जा रही है';
    if (loadingNodeId) {
      const loadingNode = findNodeInGraphDataByUid(loadingNodeId);
      if (loadingNode) {
        loadingNodeText = `${loadingNode.name} की जानकारी लाई जा रही है ...`;
      }
    }
    return loadingNodeText;
  }

  return (
      <div style={{ backgroundColor: "black" }}>
          <Graph
              graph={graphData}
              options={options}
              events={events}
              getNetwork={(network) => (networkRef.current = network)}
          />
          {loadingNodeId && <div style={{ marginTop: "10px", textAlign: "center" }}>{getLoadingText()} </div>}
      </div>
);
};

export default FamilyTreeGraph;
